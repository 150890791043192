import { Link } from 'gatsby'
import React from 'react'

const Footer = () => (
  <footer className="container mx-auto py-10 px-3 text-gray-800 font-body font-light leading-relaxed">
    <div className="max-w-lg mx-auto w-full flex flex-row justify-between mb-8">
      <Link to="/contact-us">Contact Us</Link>
      <span>| </span> <Link to="/privacy-policy">Privacy Policy</Link>
      <span>| </span> <Link to="/terms-of-use">Terms of use</Link>
    </div>
    <div className="max-w-lg mx-auto w-full flex flex-row justify-center mb-4">
      <span>© 2021 eduScrum® Australia. All rights reserved.</span>
    </div>
    <div className="max-w-2xl mx-auto w-full flex flex-row justify-center mb-8">
      <div>
        <a href="https://www.freepik.com/vectors/people">People vector created by pch.vector.</a>{' '}
        Icons made by{' '}
        <a href="https://www.freepik.com" title="Freepik">
          Freepik
        </a>
        /
        <a href="https://www.flaticon.com/" title="Flaticon">
          Flaticon
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
