import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <section id="contact-form" className="container max-w-2xl mx-auto pt-24 pb-24 text-left">
        <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
