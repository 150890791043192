import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Layout = ({ children }) => (
  <>
    <Header />
    <main className="text-gray-800">{children}</main>
    <Footer />
  </>
)

export default Layout
