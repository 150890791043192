import { graphql, Link, useStaticQuery } from 'gatsby'
import Button from '../Button'
import MobileNavigation from './MobileNavigation/index'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

const Header = () => {
  const images: Record<string, IGatsbyImageData> = useStaticQuery(graphql`
    query Logo {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  return (
    <header className="sticky top-0 bg-white shadow z-40 h-15">
      <div className="container hidden lg:flex flex-col sm:flex-row justify-between items-center mx-auto px-4 lg:px-16 h-15 ">
        <div className="flex items-center text-2xl">
          <div className="mr-3">
            <Link to="/">
              <GatsbyImage
                className="h-12 w-24"
                objectFit="contain"
                image={getImage(images.logo)}
                alt="eduScrum Logo"
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-row">
          <nav className="flex mt-4 sm:mt-0 text-xl font-display pr-8">
            <Link className="p-4 text-gray-800" to="/">
              Home
            </Link>
            <Link className="p-4 text-gray-800" to="/discover-eduscrum">
              Discover eduScrum
            </Link>
            <Link className="p-4 text-gray-800" to="/training">
              Training
            </Link>
            <Link className="p-4 text-gray-800" to="/contact-us">
              Contact us
            </Link>
          </nav>
          <div className="md:block">
            <Link to="/training#register">
              <Button size="default" className="my-1">
                Register for Training
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <MobileNavigation logo={images.logo} />
    </header>
  )
}

export default Header
