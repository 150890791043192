import { useState } from 'react'
import { Link } from 'gatsby'
import Hamburger from '../../../svg/hamburger.svg'
import Close from '../../../svg/close.svg'
import Button from '../../Button'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const MobileNavigation = ({ logo }) => {
  const [navOpen, setNavOpen] = useState(false)
  return (
    <div className="flex lg:hidden">
      {!navOpen && (
        <div className="flex flex-row justify-between w-full px-4 py-2">
          <Link to="/">
            <GatsbyImage
              className="h-12 w-24"
              objectFit="contain"
              image={getImage(logo)}
              alt="eduScrum Logo"
            />
          </Link>
          <div className="" onClick={() => setNavOpen(true)}>
            <Hamburger />
          </div>
        </div>
      )}
      {navOpen && (
        <div className="m-auto flex flex-col justify-between fixed top-0 left-0 w-full h-full bg-white z-10 font-body font-semibold text-2xl tracking-wide">
          <div className="flex flex-row justify-between w-full px-4 py-2">
            <Link to="/">
              <GatsbyImage
                className="h-12 w-24"
                objectFit="contain"
                image={getImage(logo)}
                alt="eduScrum Logo"
              />
            </Link>
            <div className="" onClick={() => setNavOpen(false)}>
              <Close />
            </div>
          </div>

          <nav>
            <div className="flex flex-col justify-center p-8 text-center">
              <Link activeClassName="underline" to="/" onClick={() => setNavOpen(false)}>
                Home
              </Link>
            </div>
            <div className="flex flex-col justify-center p-8 text-center">
              <Link
                activeClassName="underline"
                to="/discover-eduscrum"
                onClick={() => setNavOpen(false)}
              >
                Discover eduScrum
              </Link>
            </div>
            <div className="flex flex-col justify-center p-8 text-center">
              <Link activeClassName="underline" to="/training" onClick={() => setNavOpen(false)}>
                Training
              </Link>
            </div>
            <div className="flex flex-col justify-center p-8 text-center">
              <Link activeClassName="underline" to="/contact-us" onClick={() => setNavOpen(false)}>
                Contact us
              </Link>
            </div>
          </nav>
          <div className="flex flex-col justify-center p-8 text-xl">
            <Link to={`/training#register`} onClick={() => setNavOpen(false)}>
              <Button size="lg" className="w-full">
                Register for Training
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileNavigation
